import React from 'react';
import propTypes from 'prop-types';

import * as styles from './bodyText.module.css';

const BodyText = ({ bodyText }) => {
  const markdown = bodyText.childMarkdownRemark.html;
  return (
    <div className={styles.bg}>
      <div className={`container ${styles.wrapper}`}>
        <div
          className={styles.bodyText}
          dangerouslySetInnerHTML={{ __html: markdown }}
        />
      </div>
    </div>
  );
};

export default BodyText;

BodyText.propTypes = {
  bodyText: propTypes.object,
};

BodyText.defaultProps = {};
